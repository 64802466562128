<template>
  <ion-page>
    <ion-content :fullscreen="true">
      <div class="streaming-video-body">
        <div
          class="streaming-video-log"
          :class="{ 'streaming-video-log-bg': showLogCount >= 3 }"
          @click="tryShowLog"
        >
          <div v-if="showLogCount >= 3">
            <div class="clearfix">
              <div class="fr" @click="closeLog">[x]</div>
            </div>
            <div>serverDelay:{{ serverDelay }}</div>
            <div>countdown:{{ countdown_ }}</div>
            <div>upload:{{ uplinkNetworkQuality }}</div>
            <div>down:{{ downlinkNetworkQuality }}</div>
            <div>
              meetingeEnterInfo:{{
                meetingeEnterInfo
                  ? JSON.stringify(meetingeEnterInfo, null, '\t')
                  : 'null'
              }}
            </div>
            <div v-for="(log, index) in logList" :key="index">
              {{ log }}
            </div>
          </div>
        </div>
        <div
          class="streaming-lag-text"
          v-show="networkIsLow && !isStreamingBefore"
        >
          ネットワークが不安定です。<br />お確かめください。
        </div>
        <div
          class="streaming-countdown"
          v-if="countdown_ !== null && !isStreamingBefore"
          :class="{ 'streaming-gradients': countdown_ <= 5 && countStartFlag }"
        >
          <ion-icon
            :icon="timeOutline"
            class="vertical-align-sub f18"
          ></ion-icon>
          {{ `残り${countStartFlag ? formatCountdown : '--'}` }}
        </div>

        <div
          class="streaming-video-box"
          :class="{ isStreamingBefore: isStreamingBefore }"
        >
          <div
            class="mirror"
            :class="{
              'streaming-video': !changeVideo,
              'streaming-camera': changeVideo,
              'streaming-before-liver': isStreamingBefore
            }"
            id="streaming-video"
            @click="onChangeVideo(0)"
          ></div>
          <StreamingBefore
            ref="streamingBefore"
            @cameraReact="onCameraReactChange"
            @autoPlayError="autoPlayError"
            :vol="vol"
            v-if="isStreamingBefore"
          >
          </StreamingBefore>
          <div
            id="camera2"
            :class="{
              'streaming-video': changeVideo,
              'streaming-camera': !changeVideo,
              'streaming-before': isStreamingBefore
            }"
            :style="cameraStyle"
            @click="onChangeVideo(1)"
          ></div>
        </div>
        <!-- <canvas width="640" height="480" id="myCanvas"></canvas> -->
      </div>
    </ion-content>
    <alert
      v-model:isOpen="alertIsOpen"
      text="本当に退出させますか？"
      ok-text="はい"
      cancel-text="いいえ"
      type="confirm"
      @ok="closeMeeting"
      :has-close="false"
      @cancel="alertIsOpen = false"
    ></alert>
    <alert
      v-model:isOpen="autoPlayErrorAlertIsOpen"
      text="音量テストボタンをタップして、音量を調整してください"
      ok-text="次へ"
      @ok="playMusic"
      :has-close="false"
    ></alert>
    <alert
      v-model:isOpen="autoVoiceErrorAlertIsOpen"
      text="必ずカメラとマイクのアクセスを許可してください"
      ok-text="次へ"
      @ok="playRemoteStream"
      :has-close="false"
    ></alert>
    <alert
      v-model:isOpen="cameraCheckAlertIsOpen"
      :text="cameraCheckText"
      ok-text="次へ"
      @ok="reCheckCamera"
      :useHTML="true"
      :has-close="false"
    ></alert>
    <alert
      v-model:isOpen="playErrorAlertOpen"
      text="ネットワークまたはデバイスのエラーが発生しました。ページを再読み込みしてください。"
      ok-text="再読み込み"
      :isLoading="reflushing"
      @ok="reload"
      :has-close="false"
    ></alert>
    <alert
      v-model:isOpen="deviceErrorAlertOpen"
      text="カメラまたはマイクのエラーが発生しました。ページを再読み込みしてください。"
      ok-text="再読み込み"
      :isLoading="reflushing"
      @ok="reload"
      :has-close="true"
    ></alert
    ><alert
      v-model:isOpen="videoPlayErrorAlertOpen"
      text="動画または音声にエラーが発生しました。"
      ok-text="回復"
      @ok="remoteStreamResume"
      :has-close="false"
    ></alert>
    <alert
      v-model:isOpen="localVideoPlayErrorAlertOpen"
      text="カメラまたはマイクのエラーが発生しました。もう一度許可してください。"
      ok-text="許可"
      @ok="localStreamResume"
      :has-close="false"
    ></alert>
  </ion-page>
</template>

<script>
import { IonContent, IonPage, IonIcon } from '@ionic/vue'
import { close, timeOutline } from 'ionicons/icons'
import { defineComponent, markRaw } from 'vue'
import StreamingBefore from './StreamingBefore'
import Alert from './Alert'
import TRTC from 'trtc-js-sdk'
import moment from 'moment'
import { authApi } from '../api'

export default defineComponent({
  name: 'StreamingComponent',
  props: {
    // countdown: {
    //   default: null
    // }
    sendGift: {
      default: false
    }
  },
  data() {
    return {
      close,
      timeOutline,
      countdown_: null,
      timer: null,
      stream: null,
      pusher: null,
      player: null,
      client: null,
      localStream: null,
      // sendGift: false,
      changeVideo: false,
      alertIsOpen: false,
      autoPlayErrorAlertIsOpen: false,
      autoVoiceErrorAlertIsOpen: false,
      playErrorAlertOpen: false,
      deviceErrorAlertOpen: false,
      videoPlayErrorAlertOpen: false,
      localVideoPlayErrorAlertOpen: false,
      isStreamingBefore: true,
      cameraReact: {},
      vol: 0,
      volTimer: null,
      remoteStream: null,
      remoteStreamStartTimer: null,
      usedTime: 0,
      cameraCheckOk: false,
      cameraCheckAlertIsOpen: false,
      id: this.$route.params.id,
      cameraTestingResult: {},
      micTestingResult: {},
      voiceTestingResult: {},
      streamingInfo: null,
      logList: [],
      showLogCount: 0,
      uplinkNetworkQuality: 0,
      downlinkNetworkQuality: 0,
      downlinkNetworkMaxQuality: 0,
      replayTimer: null,
      localStramPauseTimer: null,
      localStramPauseMuteTimer: null,
      remoteStreamPauseTimer: null,
      reflushing: false,
      publishFlag: false,
      netLagLogSendFlag: false,
      enterTimeStamp: 0
    }
  },
  components: {
    IonContent,
    IonPage,
    IonIcon,
    Alert,
    StreamingBefore
  },
  computed: {
    networkIsLow() {
      if (this.uplinkNetworkQuality > 2 || this.downlinkNetworkQuality > 2) {
        this.setNetWordErrorLog(
          this.uplinkNetworkQuality,
          this.downlinkNetworkQuality
        )
        return true
      }
      return false
    },
    cameraCheckText() {
      const isIOS =
        /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream
      if (isIOS) {
        return `<div class="tl">カメラとマイクがブロックされています<br />ブラウザの「リフレッシュ」のアイコン<img src="/img/rotate.png" height="20" />をクリックして、ページを更新してください。</div>`
      }
      return `<div class="tl">カメラとマイクがブロックされています<br />カメラとマイクへのアクセスを許可する必要があります。ブラウザのアドレスバーのブロックされた「ロック」のアイコン<img src="/img/lock.png" height="20" />をクリックして、「権限」をクリックして、カメラとマイクをオープンしてください。</div>`
    },
    formatCountdown() {
      if (this.countdown_ >= 60) {
        return Math.floor(this.countdown_ / 60) + '分'
      } else {
        return this.countdown_ + '秒'
      }
    },
    liveIsStop() {
      return this.$store.getters.liveIsStop
    },
    cameraStyle() {
      let style = {}
      if (this.isStreamingBefore) {
        style = {
          top: (this.cameraReact['y'] || 0) + 'px'
        }
      }
      return style
    },
    goRoom() {
      let tips = false
      try {
        tips = this.meetingeEnterInfo.service && this.countdown_ <= 5
      } catch (error) {
        tips = false
      }
      return tips
    },
    sdkAppId() {
      if (!this.$store.getters.meetingeEnterInfo) {
        return ''
      }
      return this.$store.getters.meetingeEnterInfo.appId
    },
    userSig() {
      if (!this.$store.getters.meetingeEnterInfo) {
        return ''
      }
      return this.$store.getters.meetingeEnterInfo.userSig
    },
    userId() {
      if (!this.$store.getters.meetingeEnterInfo) {
        return ''
      }
      return this.$store.getters.meetingeEnterInfo.userId
    },
    liverId() {
      if (!this.$store.getters.meetingeEnterInfo) {
        return ''
      }
      return this.$store.getters.meetingeEnterInfo.trtcLiverId
    },
    meetingeEnterInfo() {
      return this.$store.getters.meetingeEnterInfo
    },
    serverDelay() {
      return this.$store.getters.serverDelay
    },
    countStartFlag() {
      return this.$store.getters.countStartFlag
    }
  },
  mounted() {
    // try {
    //   // テストする文
    //   this.openCamera()
    // } catch (e) {
    //   this.$toast.error('カメラの初期化が失敗しました')
    // }
    // this.countdown_ = this.countdown
    // if (this.countdown_ !== null) {
    //   this.onCountdown()
    // }
    // this.creatLive()
    // wait meetingeEnterInfo
    // this.initBefore()
    // setTimeout(() => {
    //   this.changeIsStreamingBefore()
    // }, 10000)
    // console.log(this.$refs.streamingBefore)
  },
  watch: {
    $route() {
      if (this.$route.name !== 'Streaming') {
        this.autoPlayErrorAlertIsOpen = false
        this.autoVoiceErrorAlertIsOpen = false
        this.cameraCheckAlertIsOpen = false
        this.$store.dispatch('setCountStartFlag', false)
        try {
          if (this.client) {
            this.leaveRoom(this.client)
          }
        } catch (error) {
          console.error(error)
        }

        // setTimeout(() => {
        //   if (this.localStream) {
        //     this.localStream.stop()
        //     this.localStream.close()
        //   }
        // }, 100)
        if (this.timer) {
          clearInterval(this.timer)
          this.timer = null
        }
        if (this.stream) {
          this.stream.getTracks().forEach(function(track) {
            track.stop()
          })
        }
        if (this.volTimer) {
          clearInterval(this.volTimer)
        }
        const timeNow = new Date().getTime()
        if (
          timeNow - this.enterTimeStamp > 10000 &&
          this.enterTimeStamp !== 0
        ) {
          // 握手超过10秒然而没有和主播建立链接的话发送E0031
          if (this.downlinkNetworkMaxQuality === 0) {
            this.sendLog('E0031')
          }
        }
      }
    },
    liveIsStop: {
      handler: function(val) {
        if (val && !this.isStreamingBefore) {
          this.checkCanStreaming()
        }
      },
      deep: true
    }
  },
  methods: {
    setNetWordErrorLog(uplinkNetworkQuality, downlinkNetworkQuality) {
      if (uplinkNetworkQuality > 2) {
        authApi.logSave({
          logType: 3,
          logInfo: `E0032:${uplinkNetworkQuality}`
        })
      }
      if (downlinkNetworkQuality > 2) {
        authApi.logSave({
          logType: 3,
          logInfo: `E0033:${downlinkNetworkQuality}`
        })
      }
    },
    reload() {
      location.reload()
      this.reflushing = true
    },
    closeLog() {
      this.showLogCount = -1
    },
    sendLog(errorCode) {
      authApi.logSave({ logType: 3, logInfo: errorCode })
    },
    checkCanStreaming() {
      authApi
        .waitingroom(this.id)
        .then(res => {
          if (this.$route.name === 'Streaming') {
            console.log(res)
            const enterData = res.data
            const startTime = enterData.startTime
            if (new Date().getTime() - this.serverDelay < startTime) {
              // this.$router.replace({ name: 'ChatRoom' })
              location.href = `/user/meeting/${this.id}/chatroom`
            } else {
              this.eventsTalk()
            }
          }
        })
        .catch(error => {
          console.error(error)
          location.href = `/user/meeting/${this.id}/chatroom`
        })
    },
    tryShowLog() {
      this.showLogCount++
    },
    async getStreamingInfo() {
      await authApi.waitingroom(this.id).then(res => {
        this.streamingInfo = res.data
        this.$store.dispatch('setLiveIsStop', res.data.livePause)
      })
    },
    localStreamResume() {
      if (this.localStream) {
        this.localStream.resume().catch(() => {
          this.deviceErrorAlertOpen = true
        })
        this.sendLog('E0001')
      }
    },
    remoteStreamResume() {
      if (this.remoteStream) {
        this.remoteStream.resume().catch(() => {
          this.deviceErrorAlertOpen = true
        })
        this.sendLog('E0004')
      }
    },
    playRemoteStream() {
      this.autoVoiceErrorAlertIsOpen = false
      this.$nextTick(() => {
        setTimeout(() => {
          if (this.remoteStream) {
            this.remoteStream.muteVideo()
            this.remoteStream.unmuteVideo()
            this.remoteStream.resume()
            this.logList.push('remoteStream resumed！！！')
          }
        }, 750)

        if (this.autoPlayErrorAlertIsOpen) {
          this.playMusic()
        }
      })
    },
    // 初始化前测试摄像头
    initBefore(noCheck) {
      if (!noCheck) {
        this.testCamera()
      }
      const documentvisibilityState = document.visibilityState
      if (documentvisibilityState === 'visible' && this.cameraCheckOk) {
        this.init()
      } else {
        setTimeout(() => {
          this.initBefore(true)
        }, 25)
      }
    },
    onCameraReactChange(react) {
      this.cameraReact = react
    },
    changeIsStreamingBefore(stat) {
      this.isStreamingBefore = stat
    },
    closeMeetingBefore() {
      this.alertIsOpen = true
    },
    closeMeeting() {
      this.alertIsOpen = false
      this.$router.replace({ name: 'Entrance' })
    },
    onChangeVideo(type) {
      if (this.isStreamingBefore) {
        return false
      }
      if (type === 0 && this.changeVideo) {
        this.changeVideo = !this.changeVideo
      } else if (type === 1 && !this.changeVideo) {
        this.changeVideo = !this.changeVideo
      }
    },
    init() {
      // debugger
      // console.log(this.$store.getters.meetingeEnterInfo)
      if (this.$store.getters.meetingeEnterInfo) {
        if (this.isStreamingBefore) {
          this.$refs.streamingBefore.initMusic()
        }
        if (!this.meetingeEnterInfo.ticket) {
          this.$router.replace({ name: 'Error' })
          return false
        }
        const startTime = this.meetingeEnterInfo.ticket.startTime
        const endTime = this.meetingeEnterInfo.ticket.endTime
        const s = moment(endTime).diff(
          new Date().getTime() - this.serverDelay,
          'seconds'
        )
        const usedTime = moment(startTime).diff(
          new Date().getTime() - this.serverDelay,
          'seconds'
        )
        console.log(usedTime)
        // if (usedTime > 0) {
        //   this.$router.replace({ name: 'IdolPage' })
        //   return false
        // }
        // if (s <= 0) {
        //   this.$router.replace({ name: 'IdolPage' })
        //   return false
        // }
        this.creatClient()
        this.countdown_ = s
        this.onCountdown()
        // setTimeout(() => {
        //   this.$emit('onAutoGift')
        // }, 5000)
      } else {
        setTimeout(() => {
          this.init()
        }, 25)
      }
    },
    // TRTC式のライブ初期化
    async creatClient() {
      // debugger
      // console.log(this.liverUserSig)
      await this.getStreamingInfo().catch(error => {
        const status = error.response.status
        switch (status) {
          default:
            location.href = `/user/meeting/${this.id}/chatroom`
            // this.$store.dispatch('addUpdateKey')
            break
        }
      })
      if (this.streamingInfo) {
        this.client = markRaw(
          TRTC.createClient({
            mode: 'rtc',
            sdkAppId: Number(this.streamingInfo.appId),
            userId: this.streamingInfo.userId,
            userSig: this.streamingInfo.userSig,
            autoSubscribe: false,
            enableAutoPlayDialog: false
          })
        )
        console.log(this.client)
        this.handleEvents(this.client)
        this.joinRoom(this.client, this.meetingeEnterInfo.roomId)
      }
    },
    joinRoom(client, roomId) {
      client
        .join({
          roomId: Number(roomId)
          // role: 'audience',
          // privateMapKey: this.streamingInfo.privateMapKey
        })
        .then(() => {
          console.log('join ok')
          this.logList.push('join ok')
          this.createStream(this.userId)
        })
        .catch(error => {
          this.$nextTick(() => {
            this.playErrorAlertOpen = true
          })
          console.error('join error ' + error)
          this.logList.push('join error:' + JSON.stringify(error))
          const errorExtraCode = Number(error.extraCode_)
          switch (errorExtraCode) {
            case 10006:
              this.sendLog('E0008')
              break
            case -10011:
              this.sendLog('E0009')
              break
            case -10012:
              this.sendLog('E0010')
              break
            case -10013:
              this.sendLog('E0011')
              break
            case -10015:
              this.sendLog('E0012')
              break
            case -10016:
              this.sendLog('E0013')
              break
            case -10017:
              this.sendLog('E0014')
              break
            case -10018:
              this.sendLog('E0015')
              break
            case -10019:
              this.sendLog('E0016')
              break
            case -10020:
              this.sendLog('E0017')
              break
            default:
              this.sendLog('E0007')
              break
          }
        })
    },
    // 摄像头检测页-检测展示摄像头设备选择列表
    async updateDeviceList() {
      // get cameras
      let cameraDevices = await TRTC.getCameras()
      this.logList.push(
        'cameraDevices:' + JSON.stringify(cameraDevices, null, '\t')
      )
      this.logList.push(
        'txy_webRTC_cameraId:' + localStorage.getItem('txy_webRTC_cameraId')
      )
      // 如果有用户设备选择缓存，优先使用缓存的deviceId
      let cacheCameraDevice = cameraDevices.filter(
        camera =>
          camera.deviceId === localStorage.getItem('txy_webRTC_cameraId')
      )
      if (cacheCameraDevice.length > 0) {
        this.cameraTestingResult.device = cacheCameraDevice[0]
      }
      // else {
      //   if (cameraDevices.length > 0) {
      //     this.cameraTestingResult.device = cameraDevices[0]
      //   } else {
      //     this.$toast.error('カメラの初期化が失敗しました')
      //     this.logList.push('カメラの初期化が失敗しました')
      //   }
      // }
      // get mic
      let micDevices = await TRTC.getMicrophones()

      // 如果有用户设备选择缓存，优先使用缓存的deviceId
      let cacheMicDevice = micDevices.filter(
        mic => mic.deviceId === localStorage.getItem('txy_webRTC_micId')
      )
      if (cacheMicDevice.length > 0) {
        this.micTestingResult.device = cacheMicDevice[0]
      } else {
        if (micDevices.length > 0) {
          this.micTestingResult.device = micDevices[0]
        } else {
          this.$toast.error('マイクの初期化が失敗しました')
          this.logList.push('マイクの初期化が失敗しました')
        }
      }

      let voiceDevices = await TRTC.getSpeakers()
      voiceDevices = voiceDevices.filter(spk => {
        return spk.deviceId.length > 0
      })
      // 如果有用户设备选择缓存，优先使用缓存的deviceId
      let cacheVoiceDevice = voiceDevices.filter(
        mic => mic.deviceId === localStorage.getItem('txy_webRTC_voiceId')
      )
      if (cacheVoiceDevice.length > 0) {
        this.voiceTestingResult.device = cacheVoiceDevice[0]
      } else if (voiceDevices.length > 1) {
        this.voiceTestingResult.device = voiceDevices[0]
      }
    },
    async createStream(userId) {
      await this.updateDeviceList()
      const streamParams = {
        userId,
        audio: true,
        video: true,
        // cameraId: this.cameraTestingResult.device.deviceId,
        // facingMode: 'user',
        microphoneId: this.micTestingResult.device.deviceId
      }
      if (this.cameraTestingResult.device) {
        streamParams['cameraId'] = this.cameraTestingResult.device.deviceId
      } else {
        streamParams['facingMode'] = 'user'
      }
      console.log('streamParams', streamParams)
      const localStream = TRTC.createStream(streamParams)
      this.localStream = markRaw(localStream)
      console.log(this.localStream)
      localStream
        .initialize()
        .then(() => {
          if (this.$route.name === 'Streaming') {
            if (this.isStreamingBefore) {
              this.onCameraReactChange(
                document.getElementById('camera').getBoundingClientRect()
              )
            }
            localStream.play('camera2').then(() => {
              setTimeout(() => {
                if (this.isStreamingBefore) {
                  this.$refs.streamingBefore.initCountDown()
                }
                this.$store.dispatch('setCountStartFlag', true)
              }, 1000)
              localStream.muteVideo()
              localStream.unmuteVideo()
              localStream.resume()
            })
            localStream.on('player-state-changed', event => {
              console.log(
                `${event.type} player is ${event.state} because of ${event.reason}`
              )
              // 若 LocalStream 触发该事件，表明音频/视频采集暂停，通常是设备异常引起，如设备被其他应用抢占，此时需引导用户重新采集。
              const state = event.state
              const reason = event.reason
              switch (state) {
                case 'PLAYING':
                  this.deviceErrorAlertOpen = false
                  this.localVideoPlayErrorAlertOpen = false
                  break
                case 'PAUSED':
                  switch (reason) {
                    case 'mute':
                      this.deviceErrorAlertOpen = true
                      if (this.localStramPauseMuteTimer) {
                        clearTimeout(this.localStramPauseMuteTimer)
                      }
                      this.localStramPauseMuteTimer = setTimeout(() => {
                        this.sendLog('E0002')
                      }, 300)
                      break
                    case 'pause':
                      this.localVideoPlayErrorAlertOpen = true
                      if (this.localStramPauseTimer) {
                        clearTimeout(this.localStramPauseTimer)
                      }
                      // this.localStramPauseTimer = setTimeout(() => {
                      //   this.sendLog('E0001')
                      // }, 300)
                      break
                    default:
                      break
                  }
                  break
                case 'STOPPED':
                  this.logList.push('localStream STOPPED！！')
                  break
                default:
                  break
              }
            })
            this.publishStream(this.localStream, this.client)
            this.volTimer = setInterval(() => {
              this.vol = Number(localStream.getAudioLevel())
            }, 50)
            this.logList.push('localStream init ok')
          } else {
            localStream.stop()
            localStream.close()
          }

          // console.log(localStream)
          // const vobj = localStream.videoPlayer_.element_
          // setTimeout(() => {
          //   document
          //     .getElementById('myCanvas')
          //     .getContext('2d')
          //     .drawImage(vobj, 0, 0, 640, 480)
          // }, 5000)
        })
        .catch(error => {
          // debugger
          // if (this.$route.name === 'Streaming') {
          //   setTimeout(() => {
          //     this.createStream()
          //   }, 500)
          // }
          // alert(error)
          this.$nextTick(() => {
            this.playErrorAlertOpen = true
          })
          const errorName = error.name
          switch (errorName) {
            case 'NotFoundError':
              this.sendLog('E0020')
              break
            case 'NotAllowedError':
              this.sendLog('E0021')
              break
            case 'NotReadableError':
              this.sendLog('E0022')
              break
            case 'OverConstrainedError':
              this.sendLog('E0023')
              break
            default:
              this.sendLog('E0024')
              break
          }

          console.error('初始化本地流失败 ' + error)
          this.logList.push('localStream init error')
        })
    },
    publishStream(localStream, client) {
      client
        .publish(localStream)
        .then(() => {
          this.publishFlag = true
          this.logList.push('publishStream ok')
          console.log('本地流发布成功')
        })
        .catch(error => {
          this.logList.push('publishStream error')
          this.playErrorAlertOpen = true
          console.error('本地流发布失败 ' + error)
          this.sendLog('E0006')
        })
    },

    handleEvents(client) {
      client.on('client-banned', () => {
        this.$router.replace({ name: 'StreamingEnd', query: { type: '1' } })
        this.logList.push('client-banned')
        console.log('被屏蔽')
      })
      client.on('stream-added', event => {
        const remoteStream = event.stream
        this.logList.push(
          'stream-added id is ' +
            remoteStream.getUserId() +
            ',liverId is ' +
            this.liverId +
            ',ID is ' +
            remoteStream.getId()
        )
        console.log('远端流增加: ' + remoteStream.getId())
        if (remoteStream.getUserId() === this.liverId) {
          this.logList.push('try subscribe')
          client.subscribe(remoteStream).catch(() => {
            this.sendLog('E0003')
            this.playErrorAlertOpen = true
          })
        }
        // autoSubscribe falseのため、一時コメントアウト
        // else {
        //   client.unsubscribe(remoteStream)
        // }
      })
      client.on('stream-removed', event => {
        const remoteStream = event.stream
        console.log('远端流减少: ' + remoteStream.getId())
        this.logList.push(
          'stream-removed ' +
            remoteStream.getUserId() +
            'liverId is ' +
            this.liverId
        )
        if (remoteStream.getUserId() === this.liverId) {
          this.logList.push('try remove remoteStream')
          remoteStream.stop()
          this.remoteStream = null
        }
      })
      client.on('network-quality', event => {
        console.log(
          `network-quality, uplinkNetworkQuality:${event.uplinkNetworkQuality}, downlinkNetworkQuality: ${event.downlinkNetworkQuality}`
        )
        this.uplinkNetworkQuality = event.uplinkNetworkQuality
        this.downlinkNetworkQuality = event.downlinkNetworkQuality
        // 记录最大的网络质量
        if (this.downlinkNetworkQuality > this.downlinkNetworkMaxQuality) {
          this.downlinkNetworkMaxQuality = this.downlinkNetworkQuality
        }
      })
      client.on('stream-subscribed', event => {
        let remoteStream = event.stream
        this.remoteStream = null
        this.remoteStream = markRaw(remoteStream)
        console.log(this.remoteStream)
        console.log('远端流订阅成功：' + remoteStream.getId())
        this.logList.push(
          'stream-subscribed ok userId is' +
            remoteStream.getUserId() +
            ',ID is ' +
            remoteStream.getId()
        )
        this.$nextTick(() => {
          if (this.remoteStreamStartTimer) {
            clearTimeout(this.remoteStreamStartTimer)
          }
          // let time = (Number(this.usedTime) + 10) * 1000
          remoteStream.play('streaming-video')
          if (this.voiceTestingResult.device) {
            remoteStream.setAudioOutput(this.voiceTestingResult.device.deviceId)
          }
          // else {
          //   this.remoteStreamStartTimer = setTimeout(() => {
          //     remoteStream.play('streaming-video')
          //   }, Math.abs(time))
          // }
          // debugger
          if (this.isStreamingBefore) {
            remoteStream.muteAudio()
            remoteStream.muteVideo()
            this.logList.push('is Streaming Before mute')
          }

          remoteStream.on('error', error => {
            const errorCode = error.getCode()
            this.logList.push('remoteStream error code is ' + errorCode)
            if (errorCode === 0x4043) {
              this.$nextTick(() => {
                this.autoVoiceErrorAlertIsOpen = true
              })
              // alert('no voice')
              // PLAY_NOT_ALLOWED,引导用户手势操作并调用 stream.resume 恢复音视频播放
              // stream.resume()
            } else {
              this.logList.push('remoteStream play is Error')
              this.$nextTick(() => {
                this.playErrorAlertOpen = true
              })
              this.sendLog('E0005')
            }
          })
          remoteStream.on('player-state-changed', event => {
            console.log(
              `${event.type} player is ${event.state} because of ${event.reason}`
            )
            const state = event.state
            const reason = event.reason
            switch (state) {
              case 'PLAYING':
                this.videoPlayErrorAlertOpen = false
                break
              case 'PAUSED':
                this.logList.push('remoteStream PAUSED！！')
                switch (reason) {
                  case 'pause':
                    this.videoPlayErrorAlertOpen = true
                    if (this.remoteStreamPauseTimer) {
                      clearTimeout(this.remoteStreamPauseTimer)
                    }
                    // this.remoteStreamPauseTimer = setTimeout(() => {
                    //   this.sendLog('E0004')
                    // }, 300)
                    break
                  default:
                    break
                }
                break
              case 'STOPPED':
                this.logList.push('remoteStream STOPPED！！')
                // this.logList.push('try remoteStream replay！！')
                // this.playErrorAlertOpen = true
                break
              default:
                break
            }
          })
        })
      })
    },
    async leaveRoom(client) {
      // 尝试stop播放器
      if (this.localStream) {
        this.localStream.stop()
      }
      if (this.remoteStream) {
        this.remoteStream.stop()
      }
      // 尝试取消发布本地流
      if (this.publishFlag) {
        await client
          .unpublish(this.localStream)
          .then(() => {
            // 取消发布本地流成功
            console.log('unpublish ok')
            this.logList.push('unpublish ok')
          })
          .catch(err => {
            console.log('unpublish error', err)
            this.logList.push('unpublish error:' + JSON.stringify(err))
          })
      }
      await client
        .leave()
        .then(() => {
          console.log('退房成功')
          this.localStream.stop()
          this.localStream.close()
          this.client = null
          this.localStream = null
        })
        .catch(error => {
          console.error('退房失败 ' + error)
        })
    },
    eventsTalk() {
      authApi.eventsTalk(this.id)
    },
    async onCountdown() {
      if (this.liveIsStop && this.isStreamingBefore) {
        this.timer = setTimeout(() => {
          this.onCountdown()
        }, 25)
      } else {
        const startTime = this.meetingeEnterInfo.ticket.startTime
        const endTime = this.meetingeEnterInfo.ticket.endTime
        const s = moment(endTime).diff(
          new Date().getTime() - this.serverDelay,
          'seconds'
        )
        const usedTime = moment(new Date().getTime() - this.serverDelay).diff(
          startTime,
          'seconds'
        )
        this.usedTime = usedTime
        console.log(usedTime)
        if (usedTime >= 0 && this.isStreamingBefore) {
          if (this.remoteStream) {
            try {
              this.remoteStream.unmuteVideo()
              this.remoteStream.unmuteAudio()
              this.logList.push('Streaming unmute!')
            } catch (error) {
              this.logList.push('Streaming unmute ERROR!')
              console.log(error)
            }
          }
          this.changeIsStreamingBefore(false)
          this.enterTimeStamp = new Date().getTime()
          this.checkCanStreaming()
          this.autoPlayErrorAlertIsOpen = false
        }
        // if (!this.sendGift && s <= 10 && this.meetingeEnterInfo.ticket.gift) {
        //   this.$emit('onAutoGift')
        // }
        this.countdown_ = s
        this.$emit('onCountdown', this.countdown_)
        if (this.countdown_ > 0) {
          this.timer = setTimeout(() => {
            this.onCountdown()
          }, 1000)
        } else {
          if (this.client) {
            await this.leaveRoom(this.client).catch(err => {
              console.log(err)
            })
          }
          this.$nextTick(() => {
            this.$emit('onCountdownEnd')
          })
        }
      }
    },
    openCamera() {
      const video = document.querySelector('#camera2')
      const streamingVideo = document.querySelector('#streaming-video')
      streamingVideo.play()

      /** カメラ設定 */
      const constraints = {
        audio: false,
        video: {
          facingMode: 'user' // フロントカメラを利用する
          // facingMode: { exact: "environment" }  // リアカメラを利用する場合
        }
      }

      /**
       * カメラを<video>と同期
       */
      navigator.mediaDevices
        .getUserMedia(constraints)
        .then(stream => {
          this.stream = stream
          video.srcObject = stream
          video.onloadedmetadata = () => {
            video.play()
          }
        })
        .catch(err => {
          console.log(err.name + ': ' + err.message)
        })
    },
    playMusic() {
      this.autoPlayErrorAlertIsOpen = false
      this.$nextTick(() => {
        if (this.isStreamingBefore) {
          // 对应safari黑屏
          // this.$refs.streamingBefore.playOrPaused()
        }
        if (this.autoVoiceErrorAlertIsOpen) {
          this.playRemoteStream()
        }
      })
    },
    autoPlayError() {
      console.log('autoPlayError!!!')
      if (!this.isStreamingBefore) {
        return false
      }
      if (this.publishFlag && this.isStreamingBefore) {
        this.$nextTick(() => {
          this.autoPlayErrorAlertIsOpen = true
        })
      } else {
        setTimeout(() => {
          this.autoPlayError()
        }, 500)
      }
    },
    reCheckCamera() {
      const isIOS =
        /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream
      if (isIOS) {
        location.reload()
      } else {
        this.testCamera()
        // this.cameraCheckAlertIsOpen = false
        // this.createStream(this.userId)
      }
    },
    // 测试摄像头
    testCamera() {
      // const that = this
      // this.cameraCheckOk = true
      // 对应safari黑屏
      navigator.getUserMedia(
        { video: { facingMode: 'user' }, audio: true },
        stream => {
          this.cameraCheckAlertIsOpen = false
          stream.getTracks().forEach(function(track) {
            track.stop()
          })
          this.cameraCheckOk = true
        },
        () => {
          this.cameraCheckOk = false
          this.cameraCheckAlertIsOpen = true
        }
      )
    }
  }
})
</script>

<style scoped>
/* #streaming-video {
  backface-visibility: hidden;
} */
/* #camera2 {
  backface-visibility: hidden;
} */
.streaming-video-box .streaming-before {
  /* position: relative; */
  position: fixed;
  z-index: 0;
  bottom: unset;
  right: unset;
  top: 155px;
  left: 50%;
  width: 246px;
  height: 329px;
  margin-left: -123px;
}
.streaming-before-liver {
  opacity: 0;
}
.streaming-go-shoot-room-tip {
  width: 260px;
  color: #fff;
  height: 36px;
  line-height: 36px;
  text-align: center;
  position: absolute;
  z-index: 3;
  top: 80px;
  left: 50%;
  margin-left: -130px;
}
.streaming-video-box {
  height: 100%;
  margin: 0 auto;
  position: relative;
  transform: translateZ(0);
  /* overflow: hidden; */
  z-index: 1;
}
.streaming-video {
  width: 100vw;
  height: 100%;
  vertical-align: middle;
  margin: 0 auto;
  overflow: hidden;
  z-index: 1;
  /* background: #fff; */
}
.streaming-video-body {
  position: relative;
  z-index: 1;
  height: 100%;
}
.streaming-camera {
  width: 98px;
  height: 156px;
  border-radius: 8px;
  position: absolute;
  z-index: 2;
  right: 22px;
  bottom: 22px;
  background: #181818;
  overflow: hidden;
}
.streaming-video-close-btn {
  position: absolute;
  z-index: 3;
  right: 10px;
  top: 10px;
  font-size: 18px;
  --background: transparent;
  width: 26px;
  height: 26px;
}
.streaming-lag-text {
  transform: translateZ(10px);
  z-index: 10;
  position: absolute;
  bottom: 180px;
  left: 0;
  right: 0;
  text-align: center;
  padding: 6px 0;
  color: #ffffff;
  font-size: 11px;
  text-shadow: black 0px 0px 3px;
}
.streaming-countdown {
  color: #fff;
  width: 166px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  position: absolute;
  z-index: 10;
  top: 6px;
  left: 50%;
  margin-left: -83px;
  background-color: rgba(224, 81, 147, 0.5);
  border-radius: 8px;
  transform: translateZ(10);
}
@media all and (orientation: landscape) {
  .streaming-video-box {
    max-width: calc(100vh / 1.778);
  }
  .isStreamingBefore.streaming-video-box {
    max-width: 100%;
  }
  .streaming-video {
    max-width: calc(100vh / 1.778);
  }
}
@media screen and (max-height: 414px) {
  .streaming-camera {
    width: 49px;
    height: 78px;
  }
}
.streaming-countdown.streaming-gradients {
  background-image: linear-gradient(
    to right,
    rgba(244, 81, 147, 1) 0%,
    rgba(244, 81, 147, 1) 40%,
    rgba(144, 193, 33, 1) 49%,
    rgba(144, 193, 33, 1) 51%,
    rgba(244, 81, 147, 1) 90%,
    rgba(244, 81, 147, 1) 100%
  );
  background-color: transparent;
  background-size: 1500%;
  background-position: 0 0;
  /* Animation */
  animation-timing-function: linear;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-name: gradients;
}
.streaming-video-log {
  position: fixed;
  left: 0;
  top: 0;
  min-width: 20px;
  min-height: 20px;
  overflow: auto;
  max-width: 100%;
  max-height: 100%;
  z-index: 99999;
  transform: translateZ(9999px);
  white-space: pre-wrap;
}
.streaming-video-log-bg {
  background: rgba(255, 255, 255, 0.6);
  padding: 5px;
  color: #000;
}
</style>
