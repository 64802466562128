<template>
  <ion-page>
    <ion-content :fullscreen="true">
      <div>
        <streaming-component
          ref="streaming"
          @onCountdownEnd="onCountdownEnd"
          @onAutoGift="onAutoGift"
          :sendGift="sendGift"
        ></streaming-component>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { defineComponent } from 'vue'
import { IonContent, IonPage } from '@ionic/vue'
import StreamingComponent from '../../../components/Streaming.vue'
// import TWebLive from 'tweblive'
import TIM from 'tim-js-sdk'
export default defineComponent({
  name: 'Streaming',
  data() {
    return {
      streamingKey: 0,
      stamp: 0,
      id: this.$route.params.id,
      userType: this.$route.meta.userType,
      giftCountdown: 0,
      videoCountdown: 0,
      giftShow: false,
      sendGift: false
    }
  },
  components: {
    IonContent,
    IonPage,
    StreamingComponent
  },
  ionViewDidEnter() {
    this.$refs.streaming.initBefore()
    // this.$refs.streaming.init()
    // this.$refs.streaming.$refs.streamingBefore.initCountDown()
  },
  mounted() {},
  computed: {
    meetingeEnterInfo() {
      return this.$store.getters.meetingeEnterInfo
    },
    meetingEvent() {
      return this.$store.getters.meetingEvent
    },
    imageGif() {
      let src = ''
      try {
        src = this.$store.getters.meetingeEnterInfo.ticket.gift.gift.imageGif
      } catch (error) {
        src = ''
      }
      return src
    },
    btnText() {
      switch (this.userType) {
        case 'c':
          return 'プレゼントをあげましたよ'
        case 'b':
          return 'プレゼントを受け取ってよ'
        default:
          return ''
      }
    },
    im() {
      return this.$store.getters.im
    }
  },
  methods: {
    onAutoGift() {
      this.stamp = new Date().getTime()
      this.giftShow = true
      this.sendGift = true
      this.sendGiftToIdol()
      setTimeout(() => {
        if (this.giftShow) {
          this.giftShow = false
        }
      }, 5000)
    },
    sendGiftToIdol() {
      const message = this.im.createCustomMessage({
        to: this.meetingeEnterInfo.trtcLiverId,
        conversationType: TIM.TYPES.CONV_C2C,
        priority: TIM.TYPES.MSG_PRIORITY_HIGH,
        payload: {
          data: 'gift',
          description: '',
          extension: ''
        }
      })
      this.im.sendMessage(message)
    },
    onCountdownEnd() {
      this.$router.replace({
        path: `/user/meeting/${this.id}/inquiry/`
      })
    }
  }
})
</script>

<style scoped>
.streaming-gift-btn-tips {
  width: 156px;
  height: 93px;
  background-image: url('/img/giftTips.png');
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  bottom: 42px;
  right: 22px;
}
.streaming-gift-btn {
  width: 52px;
  height: 52px;
  background-image: url('/img/giftBtn.png');
  background-size: cover;
  animation: bound-anim 1s infinite;
}
.streaming-gift-btn-box {
  position: relative;
  z-index: 4;
}
.streaming-gift-btn-body {
  position: fixed;
  z-index: 4;
  right: 20px;
  bottom: 223px;
}
.streaming-gift-dialog-hand {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  width: 50px;
}
.streaming-gift-dialog-mid-box {
  border: 1px dashed #e1dddd;
  position: relative;
  padding-bottom: 80px;
  z-index: 2;
}
.streaming-gift-dialog-mid-body {
  padding: 4px;
  background-color: rgba(255, 255, 255, 0.8);
  margin-top: 20px;
  position: relative;
  z-index: 1;
  /* border-radius: 23px; */
}
.streaming-gift-dialog-button-text {
  margin-top: 2px;
}
.streaming-gift-dialog-button {
  display: block;
  width: 214px;
  height: 46px;
  color: #fff;
  font-size: 14px;
  position: absolute;
  left: 0;
  width: 100%;
  height: 55px;
  bottom: 10px;
  background: #fe5ba5;
}
.streaming-gift-dialog-button-bottom {
  position: absolute;
  left: 0;
  width: 100%;
  height: 10px;
  bottom: 0px;
  background: #e05193;
}
.streaming-gift-dialog-text-body {
  margin-top: 24px;
  padding: 0 24px;
}
.streaming-gift-dialog-text-title {
  color: #e05193;
  font-size: 21px;
}
.streaming-gift-dialog-img {
  height: 97px;
  width: 97px;
}
.streaming-gift-dialog {
  width: 276px;
  height: 363px;
  position: fixed;
  z-index: 5;
  top: 50%;
  left: 50%;
  margin-left: -138px;
  margin-top: -182px;
}
.streaming-quit-btn {
  background: #e05193;
  color: #fff;
  position: absolute;
  z-index: 2;
  bottom: 22px;
  width: 102px;
  height: 34px;
  left: 50%;
  margin-left: -51px;
}
</style>
<style>
@keyframes bound-anim {
  0%,
  100% {
    transform: scale(1) translateY(0px);
  }
  30% {
    transform: scale(0.98, 1.02) translateY(-10px);
  }
  60% {
    transform: scale(1);
  }
  90% {
    transform: scale(1.13, 0.9) translateY(0px);
  }
}
</style>
