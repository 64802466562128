<template>
  <div class="live-big-body">
    <ion-header>
      <ion-toolbar>
        <div>
          <ion-title class="tc cWhite f14" v-if="meetingEvent"
            ><img :src="meetingEvent.liver.avatar" class="live-header-avatar" />
            {{ meetingEvent.liver.nickName }}の{{
              meetingEvent.eventExpress
            }}</ion-title
          >
        </div>
      </ion-toolbar>
    </ion-header>
    <ion-content
      :fullscreen="true"
      :scrollEvents="true"
      @ionScroll="getCameraReact"
    >
      <div class="common-body-720-90 pb40 live-content-body">
        <div class="live-ticket">
          <div class="live-ticket-circel left"></div>
          <div class="live-ticket-circel right"></div>
          <div class="live-ticket-status">待機中</div>
          <div class="dib live-ticket-info-box">
            <!-- <ion-icon
              :icon="personOutline"
              class="live-ticket-icon pr5"
            ></ion-icon> -->
            <span class="f12" v-if="meetingeEnterInfo" key="0"
              >No.{{ meetingeEnterInfo.index }}</span
            >
          </div>
          <div class="dib live-ticket-info-box">
            <ion-icon
              :icon="timeOutline"
              class="live-ticket-icon pr5"
            ></ion-icon>
            <span class="f12" key="1" v-if="liveIsStop">準備中</span>
            <span class="f12" key="0" v-else
              >残り{{
                countDown > 31536000 ? '--' : countStartFlag ? countDown : '--'
              }}秒</span
            >
          </div>
        </div>
        <div class="tc live-video-body">
          <div class="live-video-gray-tips-body">
            <div class="live-video-gray-tips">カメラの映像</div>
          </div>
          <div id="camera" class="live-video"></div>
          <div class="clearfix live-video-tool-body">
            <div class="fl live-video-circle-body">
              <div class="live-video-mic live-video-circle dflex flexCenter">
                <div
                  class="live-video-mic-p"
                  :style="{ height: `${vol * 4 * 100}%` }"
                ></div>
                <ion-icon :icon="micOutline"></ion-icon>
              </div>
              <div class="live-video-tool-text">マイクテスト</div>
            </div>
            <div class="fl live-video-circle-body">
              <div
                class="live-video-player live-video-circle dflex flexCenter"
                @click="playOrPaused"
              >
                <ion-icon :icon="play" v-show="!playMusic"></ion-icon>
                <ion-icon :icon="pause" v-show="playMusic"></ion-icon>
              </div>
              <div class="live-video-tool-text">音量テスト</div>
            </div>
          </div>
          <!-- <div v-if="localStream">{{ vol }}</div> -->
          <!-- <div class="live-countdown-bg" v-show="countDown <= 10"></div> -->
          <div
            class="live-count-down"
            v-if="countDown > 0 && countDown <= 10 && countStartFlag"
          >
            {{ countDown }}
          </div>
        </div>
        <div class="live-tips" v-if="userType === 'c'" key="c">
          <div class="live-tips-title">*お願い</div>
          デバイスの録画機能、スクリーンショットの使用はおやめください。<br />
          上記のマイク・スピーカーアイコンで最終確認をしてください。
        </div>
      </div>
    </ion-content>
    <alert
      v-model:isOpen="alertIsOpen"
      text="本当に退出させますか？"
      ok-text="はい"
      cancel-text="いいえ"
      type="confirm"
      @ok="closeMeeting"
      :has-close="false"
      @cancel="alertIsOpen = false"
    ></alert>
    <StopAlert />
  </div>
</template>

<script>
import {
  IonContent,
  IonHeader,
  IonToolbar,
  IonIcon,
  IonTitle
} from '@ionic/vue'
import { defineComponent } from 'vue'
import {
  close,
  timeOutline,
  personOutline,
  micOutline,
  play,
  pause
} from 'ionicons/icons'
import Alert from './Alert'
import TRTC from 'trtc-js-sdk'
import moment from 'moment'
import StopAlert from './StopAlert.vue'

export default defineComponent({
  name: 'StreamingBefore',
  props: {
    vol: { type: Number, default: 0 }
  },
  data() {
    return {
      alertIsOpen: false,
      playMusic: false,
      music: new Audio(),
      id: this.$route.params.id,
      userType: this.$route.meta.userType,
      countDownTimer: null,
      countDown: 0,
      close,
      timeOutline,
      personOutline,
      micOutline,
      play,
      pause,
      userCount: 20,
      maxUserCount: 200,
      stream: null,
      cameraTestingResult: {},
      micTestingResult: {},
      localStream: null
    }
  },
  components: {
    IonContent,
    IonHeader,
    IonToolbar,
    IonIcon,
    IonTitle,
    Alert,
    StopAlert
  },
  computed: {
    meetingeEnterInfo() {
      return this.$store.getters.meetingeEnterInfo
    },
    meetingEvent() {
      return this.$store.getters.meetingEvent
    },
    serverDelay() {
      return this.$store.getters.serverDelay
    },
    liveIsStop() {
      return this.$store.getters.liveIsStop
    },
    countStartFlag() {
      return this.$store.getters.countStartFlag
    }
  },
  mounted() {},
  watch: {
    $route() {
      if (this.$route.name !== 'Streaming') {
        if (this.countDownTimer) {
          clearInterval(this.countDownTimer)
          this.countDownTimer = null
        }
        if (this.stream) {
          this.stream.getTracks().forEach(function(track) {
            track.stop()
          })
        }
        if (this.localStream) {
          this.localStream.stop()
          this.localStream.close()
        }
        this.music.pause()
      }
    },
    liveIsStop: {
      handler: function(val) {
        if (!val) {
          this.countDown = 31536001
        }
      },
      deep: true
    }
  },
  unmounted() {
    if (this.countDownTimer) {
      clearInterval(this.countDownTimer)
      this.countDownTimer = null
    }
    if (this.stream) {
      this.stream.getTracks().forEach(function(track) {
        track.stop()
      })
    }
    if (this.localStream) {
      this.localStream.stop()
      this.localStream.close()
    }
    this.music.pause()
  },
  methods: {
    getCameraReact() {
      const react = document.getElementById('camera')?.getBoundingClientRect()
      if (react) {
        this.$emit('cameraReact', react)
      }
    },
    initMusic() {
      this.music.onplay = () => {
        this.playMusic = true
      }
      this.music.onpause = () => {
        this.playMusic = false
      }
      this.music.preload = 'auto'
      this.music.src =
        this.meetingeEnterInfo.soundUrl ||
        'https://rococo-1259241735.file.myqcloud.com/prd/mp3/sample.mp3'
      this.music.load()
      this.music.volume = 1
      this.music.loop = true
      // this.music.autoplay = true
      // 对应safari黑屏
      // const playPromise = this.music.play()
      // if (playPromise !== undefined) {
      //   playPromise
      //     .then(() => {
      //       // Autoplay started!
      //     })
      //     .catch(error => {
      //       this.$emit('autoPlayError', error)
      //     })
      // }
    },
    playOrPaused() {
      if (this.playMusic) {
        this.music.pause()
      } else {
        this.music.play()
      }
    },
    closeMeetingBefore() {
      this.alertIsOpen = true
    },
    closeMeeting() {
      this.$router.replace({ name: 'Entrance' })
    },
    initCountDown() {
      if (this.$store.getters.meetingeEnterInfo) {
        setTimeout(() => {
          this.getCameraReact()
        }, 100)
        const startTime = this.meetingeEnterInfo.ticket?.startTime || 0
        const s = moment(startTime).diff(
          new Date().getTime() - this.serverDelay,
          'seconds'
        )
        this.countDown = s < 0 ? 0 : s
        // this.countDownFn()
        this.countDownTimer = setInterval(() => {
          this.getCameraReact()
          this.countDownFn()
        }, 1000)
      } else {
        setTimeout(() => {
          this.initCountDown()
        }, 25)
      }
    },
    // 摄像头检测页-检测展示摄像头设备选择列表
    async updateDeviceList() {
      // get cameras
      let cameraDevices = await TRTC.getCameras()

      // 如果有用户设备选择缓存，优先使用缓存的deviceId
      let cacheCameraDevice = cameraDevices.filter(
        camera =>
          camera.deviceId === localStorage.getItem('txy_webRTC_cameraId')
      )
      if (cacheCameraDevice.length > 0) {
        this.cameraTestingResult.device = cacheCameraDevice[0]
      } else {
        if (cameraDevices.length > 0) {
          this.cameraTestingResult.device = cameraDevices[0]
        } else {
          this.$toast.error('カメラの初期化が失敗しました')
        }
      }
      // get voice
      let micDevices = await TRTC.getMicrophones()

      // 如果有用户设备选择缓存，优先使用缓存的deviceId
      let cacheMicDevice = micDevices.filter(
        mic => mic.deviceId === localStorage.getItem('txy_webRTC_micId')
      )
      if (cacheMicDevice.length > 0) {
        this.micTestingResult.device = cacheMicDevice[0]
      } else {
        if (micDevices.length > 0) {
          this.micTestingResult.device = micDevices[0]
        } else {
          this.$toast.error('マイクの初期化が失敗しました')
        }
      }
    },
    async startTesting() {
      // $('#camera-testing-body').show()
      // this.curTestingPageId = 'camera-testing-body'
      // $('#camera-testing').removeClass('icon-normal').addClass('icon-blue complete')
      // this.completedTestingPageIdList.push('camera-testing-body')
      // this.completedTestingPageIdList = [...new Set(this.completedTestingPageIdList)]

      await this.updateDeviceList()

      // 创建本地视频流
      this.localStream = TRTC.createStream({
        audio: true,
        video: true,
        cameraId: this.cameraTestingResult.device.deviceId,
        microphoneId: this.micTestingResult.device.deviceId
      })
      this.localStream.initialize()
      this.localStream.play('camera')
    },
    countDownFn() {
      if (!this.liveIsStop) {
        if (this.countDown > 1) {
          const startTime = this.meetingeEnterInfo.ticket.startTime
          this.countDown = moment(startTime).diff(
            new Date().getTime() - this.serverDelay,
            'seconds'
          )
          // this.countDown--
        } else {
          clearInterval(this.countDownTimer)
        }
      }
    },
    openCamera() {
      const video = document.querySelector('#camera')

      /** カメラ設定 */
      const constraints = {
        audio: false,
        video: {
          facingMode: 'user' // フロントカメラを利用する
          // facingMode: { exact: "environment" }  // リアカメラを利用する場合
        }
      }

      /**
       * カメラを<video>と同期
       */
      navigator.mediaDevices
        .getUserMedia(constraints)
        .then(stream => {
          video.srcObject = stream
          this.stream = stream
          video.onloadedmetadata = () => {
            video.play()
          }
        })
        .catch(err => {
          console.log(err.name + ': ' + err.message)
        })
    }
  }
})
</script>

<style scoped>
.live-video-tool-text {
  color: #fff;
  font-size: 8px;
  text-align: center;
}
.live-content-body {
  padding-top: 10px;
}
ion-content {
  --ion-background-color: transparent;
}
.live-big-body {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  position: fixed;
  flex-direction: column;
  justify-content: space-between;
  contain: layout size style;
  overflow: hidden;
  z-index: 10;
}
.live-video-tool-body {
  width: 164px;
  position: absolute;
  bottom: 10px;
  left: 50%;
  margin-left: -76px;
}
.live-video-player {
  background: #e05193;
}
.live-video-mic-p {
  background: #e05193;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  /* border-radius: 100%; */
}
.live-video-mic {
  background: rgba(255, 255, 255, 0.6);
  position: relative;
  z-index: 1;
}
.live-video-circle {
  width: 34px;
  height: 34px;
  line-height: 34px;
  color: #fff;
  font-size: 18px;
  border-radius: 17px;
  overflow: hidden;
  margin: 0 auto;
  /* float: left; */
}
.live-video-circle-body {
  margin: 0 10px;
  width: 60px;
  white-space: nowrap;
}
.live-countdown-bg {
  height: 50vh;
  width: 100%;
}
.live-ticket-icon {
  vertical-align: -3px;
}
.live-ticket-info-box {
  margin-top: 6px;
  width: 40%;
}
.live-ticket-circel.left {
  left: -24px;
}
.live-ticket-circel.right {
  right: -24px;
}
.live-ticket-circel {
  width: 40px;
  height: 40px;
  background: #181818;
  border-radius: 20px;
  position: absolute;
  z-index: 2;
  top: 50%;
  margin-top: -20px;
}
.live-video {
  max-width: 90%;
  width: auto;
  height: 100%;
  border-radius: 12px;
  vertical-align: middle;
  z-index: 1;
  display: inline-block;
  border-radius: 20px;
  overflow: hidden;
}
.live-header-avatar {
  display: inline-block;
  height: 32px;
  width: 32px;
  margin-right: 3px;
  border-radius: 16px;
}
.live-ticket {
  height: 71px;
  width: 100%;
  background: #852a56;
  margin-bottom: 30px;
  color: #fff;
  text-align: center;
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.live-ticket-status {
  padding-top: 10px;
}
.live-tips-title {
  font-size: 14px;
  text-align: center;
}
.live-tips {
  width: 90%;
  margin: 30px auto 0 auto;
  color: #fff;
  background-color: #7c3456;
  font-size: 12px;
  padding: 15px 20px;
  border-radius: 5px;
}
.live-video-gray-tips {
  padding: 10px 20px;
  background: #4b4a4b;
  display: inline-block;
  color: #fff;
  border-radius: 20px;
  font-size: 12px;
  min-height: 38px;
}
.live-video-gray-tips-body {
  position: absolute;
  top: -20px;
  text-align: center;
  width: 100%;
  z-index: 2;
}
.live-video-body {
  position: relative;
  height: 329px;
  z-index: 1;
}
.live-count-down {
  position: absolute;
  z-index: 2;
  width: 180px;
  height: 76px;
  left: 50%;
  top: 50%;
  margin-left: -90px;
  margin-top: -38px;
  font-size: 74px;
  color: #e05193;
  text-shadow: rgba(0, 0, 0, 0.6) 1px 0 10px;
}
</style>
